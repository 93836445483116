@import '../../pages/variables.scss';

.tab {
  z-index: 999;
  position: fixed;
  bottom: 0;
  right: 0;
  background: $purple;
  padding: 10px;

  * {
    color: $white;
    font-family: DIN, sans-serif;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  button {
    background: none;
    border: none;
    font-weight: bold;
    margin: 0;

    &:hover {
      cursor: pointer;
    }
  }
}
