@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Raleway', sans-serif;
}

* {
  box-sizing: border-box;
  outline: none;
}

.css-1vr111p-option {
  color: black !important;
}

.MuiInputBase-root,
.MuiButtonBase-root,
.basic-multi-select {
  max-width: 100%;
  border-radius: 0 !important;
}

.MuiTypography-body1,
.MuiTypography-body2 {
  font-family: 'Archivo Narrow';
}

.MuiInputBase-input {
  font-family: 'Archivo Narrow' !important;
}

@font-face {
  font-family: 'DIN';
  src: local('DIN'), url(./assets/fonts/DIN.ttf) format('truetype');
}

@font-face {
  font-family: 'DIN Bold';
  src: local('DIN Bold'), url(./assets/fonts/DIN-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'URW DIN SemiCond';
  src: local('URW DIN SemiCond'),
    url(./assets/fonts/URW_DIN_SemiCond.ttf) format('truetype');
}

/* @font-face {
  font-family: 'Archivo Narrow';
  src: url('./assets/fonts/archivonarrow-regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/archivonarrow-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: 'Archivo Narrow';
  src: url(./assets/fonts/ArchivoNarrow-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Archivo Narrow Bold';
  src: url(./assets/fonts/ArchivoNarrow-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Archivo Narrow Medium';
  src: url(./assets/fonts/ArchivoNarrow-Medium.ttf) format('truetype');
}

/* Checkmark style overrides */
.container {
  display: block;
  position: relative;

  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: white;
  border: 2px solid #2c0040;
  border-radius: 2px;
}
.container:hover input ~ .checkmark {
  background-color: white;
}
.container input:checked ~ .checkmark {
  background-color: transparent;
}
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.container input:checked ~ .checkmark:after {
  display: block;
}
.container .checkmark:after {
  left: 4px;
  top: 0px;
  width: 4px;
  height: 10px;
  border: solid #2c0040;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
